<template>
  <div>
    <b-overlay :show="loading" rounded="sm" no-fade>
      <b-card>
        <b-row>
          <b-col xl="12" md="12" sm="12" cols="12">
            <h1 class="text-primary my-1">Import Excel Purchase Order</h1>
            <b-row>
              <b-col cols="6" xl="6" md="12" sm="12">
                <b-form-group class="mb-40">
                  <div>
                    <b-form-file accept=".xls, .xlsx" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." @change="importExcelFile" ref="file-input" />
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="6" xl="6" md="12" sm="12">
                <b-form-group class="mb-40">
                  <b-input-group>
                    <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search"></b-form-input>
                    <b-input-group-append>
                      <b-button @click="filter = ''" variant="primary">Clear</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-table
              :items="listInvoices"
              :small="true"
              responsive
              selectable
              select-mode="single"
              style="padding-bottom:30px"
              class="position-relative "
              head-variant="dark"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              :fields="inHeaders"
              striped
              hover
              :current-page="currentPage"
              :per-page="perPage"
              @row-selected="onRowSelected"
            >
              <template #head(invoiceStatus)>
                <feather-icon icon="TrendingUpIcon" class="mx-auto" />
              </template>

              <!-- Column: Id -->
              <template #cell(id)="data">
                <strong class="text-primary"> #{{ data.value }} </strong>
              </template>

              <template #cell(customerId)="data">
                <strong class="font-weight-bold"> #{{ data.value }} </strong>
              </template>

              <template #cell(userId)="data">
                <strong class="font-weight-bold"> #{{ data.value }} </strong>
              </template>

              <template #cell(vehicleId)="data">
                <strong class="font-weight-bold"> #{{ data.value }} </strong>
              </template>

              <!-- Column: Issued Date -->
              <template #cell(date)="data">
                <span class="text-nowrap" v-if="data.value != '01 Jan 1970'">
                  {{ data.value }}
                </span>

                <span v-else class="text-nowrap">
                  N/A
                </span>
              </template>

              <!-- Column: Customer -->
              <template #cell(get_customer)="data">
                <span class="text-nowrap" v-if="data.value.firstName != undefined">
                  {{ data.value.firstName + ' ' + data.value.lastName }}
                </span>

                <span class="text-nowrap" v-else>
                  N/A
                </span>
              </template>
              <!-- Column: INVOICE total -->
              <template #cell(total)="data">
                <span class="text-nowrap">
                  $
                  {{ data.value ? formatPrice(data.value, 2) : formatPrice(0) }}
                </span>
              </template>

              <template #cell(totalPaid)="data">
                <span class="text-nowrap">
                  $
                  {{ data.value ? formatPrice(data.value, 2) : formatPrice(0) }}
                </span>
              </template>
              <template #cell(leftTotal)="data">
                <span class="text-nowrap">
                  $
                  {{ data.value ? formatPrice(data.value, 2) : formatPrice(0) }}
                </span>
              </template>

              <template #cell(status)="data">
                <b-badge v-if="data.item.status == 'A'" pill variant="dark" class="badge-glow">Draft</b-badge>
                <b-badge v-if="data.item.status == 'B'" pill variant="primary" class="badge-glow">Sent To Manager</b-badge>
                <b-badge v-if="data.item.status == 'C'" pill variant="success" class="badge-glow">Payment Made</b-badge>
                <b-badge v-if="data.item.status == 'D' && data.item.pastDue == null" pill variant="warning" class="badge-glow">Partial Payment</b-badge>
                <b-badge v-if="data.item.status == 'D' && data.item.pastDue == 'yes'" pill variant="light-danger" class="badge-glow">Partial Payment<br /><small>Past Due</small></b-badge>
                <b-badge v-if="data.item.status == 'F' && data.item.pastDue == null" pill variant="info" class="badge-glow">Approved</b-badge>
                <b-badge v-if="data.item.status == 'F' && data.item.pastDue == 'yes'" pill variant="light-danger" class="badge-glow">Approved<br /><small>Past Due</small></b-badge>
                <b-badge v-if="data.item.status == 'G'" pill variant="danger" class="badge-glow">Not Approved</b-badge>
                <b-badge v-if="data.item.status == 'H'" pill variant="success" class="badge-glow">Completed Order</b-badge>
                <b-badge v-if="data.item.status == 'J'" pill variant="danger" class="badge-glow">Canceled Order</b-badge>

                <!-- <b-badge v-if="data.item.status == 'D'" pill variant="light-info" class="badge-glow">Incoming </b-badge>
            <b-badge v-if="data.item.status == 'I'" pill variant="light-info" class="badge-glow">In Stock Pending</b-badge> -->
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <div class="text-nowrap">
                  <!-- Dropdown -->
                  <b-dropdown variant="link" toggle-class="p-0" no-caret :right="$store.state.appConfig.isRTL">
                    <template #button-content>
                      <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                    </template>
                    <b-dropdown-item
                      :to="{
                        name: 'expense-preview',
                        params: { id: data.item.id },
                      }"
                    >
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">Preview</span>
                    </b-dropdown-item>

                    <!-- <b-dropdown-item>
                <feather-icon icon="DownloadIcon" />
                <span class="align-middle ml-50">Download</span>
              </b-dropdown-item> -->
                    <b-dropdown-item
                      :to="{
                        name: 'expense-edit',
                        params: { id: data.item.id },
                      }"
                    >
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">Edit</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
            <div class="mx-2 mb-2">
              <b-row>
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                </b-col>

                <!-- Pagination -->
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { BFormFile } from 'bootstrap-vue';
import axiosIns from '@/libs/axios';
import store from '@/store';
import router from '@/router';
import { onUnmounted } from '@vue/composition-api';
import settingsStoreModule from '../settingsStoreModule';
import moment from 'moment';
import _ from 'lodash';

export default {
  components: {
    BFormFile,
  },
  mounted() {
    this.getItems();
  },
  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });

    return { selected: ['Admin', 'User'] };
  },
  data() {
    return {
      excelJson: {},
      excelJsonSheet2: {},
      edit: null,
      newitem: '',
      filter: null,
      filterOn: [],
      perPage: localStorage.getItem('invoiceShow') ?? 10,
      loading: false,
      items: [],
      where: router.currentRoute.name,
      currentPage: 1,
      pageOptions: [5, 10, 15, 20],
      totalRows: 0,
      fetchPurchase: [],
      listInvoices: [],
      inHeaders: [],
      colWidthFactor: null,
      tempStatusOptions: [
        { label: 'Draft', value: 'A' },
        { label: 'Pending Manager', value: 'B' },
        { label: 'Approved', value: 'C' },
        { label: 'Not Approved', value: 'D' },
        { label: 'Sent To Client', value: 'E' },
        { label: 'Win', value: 'F' },
        { label: 'Loss', value: 'P' },
        { label: 'Canceled Sale', value: 'J' },
        { label: 'Win-Closed', value: 'H' },
      ],
    };
  },
  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  methods: {
    onRowSelected(item) {
      if (this.$Can('inventory_show')) {
        router.push({ name: 'expense-preview', params: { id: item[0].id } });
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getItems(val) {
      this.loading = true;
      store
        .dispatch('settings/getAllPurchaseOrderQuote', [])
        .then((res) => {
          let filterExcelImportTrue = _.filter(res.data.data, function(o) {
            return o.excelImport;
          });

          this.getColumns(res.data.headers, filterExcelImportTrue);

          this.totalRows = filterExcelImportTrue.length;
          //this.fetchPurchase = filterExcelImportTrue;

          var arrayStatus = [];

          filterExcelImportTrue.forEach((element) => {
            if (element) {
              arrayStatus.push(element.status);
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getColumns(userheaders, data) {
      store
        .dispatch('settings/fetchPurchaseOrderColumns', [])
        .then((res) => {
          this.createTable(userheaders, res.data);

          this.fetchInvoices = data;
          this.listInvoices = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createTable(userheaders, headerColumns) {
      if (userheaders) {
        userheaders.forEach((items) => {
          this.colWidthFactor = this.colWidthFactor + headerColumns.find((x) => x.value == items).percentage;
        });

        userheaders.forEach((items) => {
          var columns = headerColumns.find((x) => x.value == items);
          var width = 'width: ' + String(columns.percentage * (95 / this.colWidthFactor)) + '%';
          this.inHeaders.push({
            key: items,
            label: columns.title,
            sortable: true,
            class: 'text-center',
            thStyle: width,
          });
        });
        if (this.$Can('inventory_show') || this.$Can('inventory_edit')) {
          this.inHeaders.push({
            key: 'actions',
            class: 'text-center',
            thStyle: 'width: 5%',
          });
        }
        this.loading = false;
      }
    },
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },
    importExcelFile(e) {
      var files = e.target.files,
        f = files[0];
      var reader = new FileReader();
      const self = this;
      reader.onload = function(e) {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: 'array' });
        let sheetName = workbook.SheetNames[0];
        let sheetName2 = workbook.SheetNames[1];

        let worksheet = workbook.Sheets[sheetName];
        let worksheet2 = workbook.Sheets[sheetName2];

        this.excelJson = XLSX.utils.sheet_to_json(worksheet, { defval: null });
        this.excelJsonSheet2 = XLSX.utils.sheet_to_json(worksheet2, {
          defval: null,
        });

        let controlExcelColumn = 0;
        let matchRegex = /(id|stockNumber|note|firstName|lastName)/g;

        if (this.excelJson.length > 0 && this.excelJson != {}) {
          for (let x in this.excelJson[0]) {
            if (x.match(matchRegex)) {
              controlExcelColumn += 1;
            }
          }
          if (controlExcelColumn === 5) {
            this.excelJson.forEach((date) => {
              if (!date['date'].toString().includes('-')) {
                date['date'] = self.excelDateToJSDate(date['date']);
              }
              if (!date['dueDate'].toString().includes('-')) {
                date['dueDate'] = self.excelDateToJSDate(date['dueDate']);
              }
            });

            //object assing main sheet2 items to sheet1 item
            this.excelJson.forEach((mainItem) => {
              mainItem.items = [];
              this.excelJsonSheet2.forEach((item) => {
                if (mainItem.id === item.orderLine) {
                  mainItem.items.push({
                    itemTitle: item.itemTitle,
                    itemCategory: item.itemCategory
                      .toString()
                      .toUpperCase()
                      .trim(),
                    inventoryId: item.stockNumber,
                    description: item.description,
                    cost: item.cost,
                    discount: item.discount,
                    gst: item.gst,
                    pst: item.pst,
                    qty: item.qty,
                    vehicleValue: null,
                  });
                }
              });
            });

            //Excel json in without id because we don't need id in json just need sheet2 data in orlerline match id
            this.excelJson = _.map(this.excelJson, (o) => _.omit(o, ['id']));

            axiosIns
              .post('import-excel-purchase-order', this.excelJson)
              .then((res) => {
                self.$refs['file-input'].reset();
                self.getItems();
                self.$swal({
                  title: 'Success',
                  text: 'Excel import successfully',
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                });
              })
              .catch((error) => {
                self.$swal({
                  title: 'Error!',
                  text: 'Oops, Something went wrong while loading excel, please try again',
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                });

                console.log(error);
              });
          } else {
            self.$swal({
              title: 'Error!',
              text: 'Oops, This excel table headers not correct. Make sure the headers are correct',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            });
          }
        }
      };
      reader.readAsArrayBuffer(f);
    },
    excelDateToJSDate(serial) {
      var utc_days = Math.floor(serial - 25569);
      var utc_value = utc_days * 86400;
      var date_info = new Date(utc_value * 1000);
      return date_info.getDate() + '-' + (date_info.getMonth() + 1) + '-' + date_info.getFullYear();
    },
  },
  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem('invoiceShow', val);
      },
    },
  },
};
</script>
